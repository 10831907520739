import React from "react";
import "./styles.css";
import { motion } from "framer-motion";
// Include your Tailwind styles
import { useTranslation } from "react-i18next";

//Azul Caelum #002e60
//Gris Caelum #b2b3b5

const ReservationPage = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="bg-[#000000] text-white min-h-screen flex flex-col">
      <header className="flex justify-between items-center p-4">
        <img
          src="LogoCaelumResto.jpg"
          alt="Caelum Restó"
          className="w-24 h-24"
        />
        <nav className="flex items-center ">
          <a href="/menu" className="px-4">
            {t("seeMenu")}
          </a>
          <a href="/reservas" className="px-4">
            {t("bookTable")}
          </a>
          <button
            className="px-4"
            onClick={() => {
              const lang =
                i18n.language === "es"
                  ? "en"
                  : i18n.language === "en"
                  ? "pt"
                  : "es";
              handleLanguageChange(lang);
            }}
          >
            <img
              src={`${i18n.language}.svg`}
              alt={`${i18n.language} flag`}
              className="w-6 h-6"
            />
          </button>
        </nav>
      </header>

      {/* Seccion Hook */}
      <section className="flex flex-col items-center justify-center text-left px-0 bg-[#000000] md:flex-row md:justify-between">
        <div className="md:w-1/2">
          <h2 className="text-4xl font-semibold px-4 md:px-20">
            {t("uniqueDiningExperience")}
          </h2>
          <p className="max-w-4xl px-4 md:px-20 mt-6 mb-6">
            {t("restaurantDescription")}
          </p>
          <div className="flex flex-col md:flex-row md:space-x-4 mb-4">
            <div className="rounded-lg">
              <h3 className="text-lg font-bold px-4 md:px-20">
                {t("operatingHours")}
              </h3>
              <p className="px-4 md:px-20">{t("openHours")}</p>
              <p className="px-4 md:px-20">{t("closedDay")}</p>
              <h3 className="text-lg font-bold px-4 md:px-20 mt-4">
                {t("address")}
              </h3>
              <a
                href="https://maps.app.goo.gl/Mn9wFpZtAuzUk1o37"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 md:px-20"
              >
                {t("CaelumRestoAddress")}
              </a>
            </div>
          </div>
        </div>
        <div className="md:block md:w-1/2">
          <AnimatedImage
            fetchpriority="high"
            src={"/resto.jpg"}
            alt={"Copa de Vino"}
          />
        </div>
      </section>

      {/* Seccion Terroire */}
      <section className="flex flex-col items-center justify-center mx-4 md:flex-row md:space-x-4 text-center my-12 bg-[#000000]">
        <div className="md:w-1/2">
          <div className="flex items-center justify-center">
            <h2 className="text-3xl font-semibold mb-4">
              {t("authenticTerroirMenu")}
            </h2>
          </div>
          <div className="flex items-center justify-center">
            <p className="max-w-xl mb-6 text-justify">
              {t("terroirMenuDescription")}
            </p>
          </div>
        </div>
        <div className="md:w-1/2">
          <div className="flex items-center justify-center">
            <h2 className="text-3xl font-semibold mb-4">
              {t("freeFlowingWinePairings")}
            </h2>
          </div>
          <div className="flex items-center justify-center">
            <p className="max-w-xl mb-6 text-justify">
              {t("winePairingsDescription")}
            </p>
          </div>
        </div>
      </section>

      {/* Seccion Entradas */}
      <section className="flex flex-col items-center justify-center text-left md:flex-row md:justify-between md:mx-24">
        <div className="md:block md:w-1/2">
          <AnimatedImage src={"/entrada3.jpg"} alt={t("menu.entradas.item3")} />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-4xl font-semibold px-4 md:px-20 mb-4 mt-4 md:mt-0">
            {t("starters")}
          </h2>
          <p className="max-w-3xl px-4 md:px-20 mb-6 text-left">
            {t("startersDescription")}
          </p>
        </div>
      </section>

      {/* Seccion Principal */}
      <section className="flex flex-col md:flex-row-reverse md:items-center md:justify-between md:mx-24 ">
        <div className="md:block md:w-1/2 order-last md:order-last">
          <h2 className="text-4xl font-semibold px-4 md:px-20 mb-4 mt-4 md:mt-0">
            {t("mains")}
          </h2>
          <p className="max-w-3xl px-4 md:px-20 mb-6 text-left">
            {t("mainsDescription")}
          </p>
        </div>
        <div className="md:block md:w-1/2 order-first md:order-first">
          <AnimatedImage
            src={"/principal.jpg"}
            alt={t("menu.principales.item1")}
          />
        </div>
      </section>

      {/* Seccion Postres */}
      <section className=" flex flex-col items-center justify-center text-left md:flex-row md:justify-between md:mx-24">
        <div className="md:block md:w-1/2">
          <AnimatedImage src={"/postres4.jpg"} alt={t("menu.postre.item1")} />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-4xl font-semibold px-4 md:px-20 mb-4 mt-4 md:mt-0">
            {t("desserts")}
          </h2>
          <p className="max-w-3xl px-4 md:px-20 mb-6 text-left">
            {t("dessertsDescription")}
          </p>
        </div>
      </section>
      <div className=" md:flex md:items-center md:justify-center">
        <div className="md:w-1/2 pr-8">
          <div className="">
            <h2 className="text-3xl font-semibold text-center mb-4">
              {t("discoverCaelumExperience")}
            </h2>
            <p className="text-left px-4 md:px-20 mb-2">
              {t("caelumExperienceDescription1")}
            </p>
            <p className="text-left px-4 md:px-20 mb-2">
              {t("caelumExperienceDescription2")}
            </p>
            <p className="text-left px-4 md:px-20 mb-2">
              {t("caelumExperienceDescription3")}
            </p>
          </div>
        </div>
        <div className=" md:w-1/2 ">
          <AnimatedImage src={"/equipo.jpg"} alt={t("menu.postre.item1")} />
        </div>
      </div>
      <div className="flex items-center justify-center mt-8">
        <div className="md:w-1/4 w-full p-4">
          <h3 className="text-2xl font-bold text-center">{t("bookATable")}</h3>

          <button
            className="mt-4 mx-2 w-full px-4 py-2 text-sm font-bold text-white bg-[#800000] hover:bg-[#002e60]/90 rounded-full"
            onClick={() => (window.location.href = "/booking")}
          >
            {t("bookATable")}
          </button>
        </div>
      </div>
      <footer className=" items-center justify-center"></footer>
    </div>
  );
};

export default ReservationPage;

const AnimatedImage = ({ src, alt }) => {
  return (
    <motion.img
      src={src}
      alt={alt}
      whileHover={{ opacity: 1.0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2.5 }}
      className=" object-cover w-full"
    />
  );
};
