import { useAuth } from "../auth/auth";
import LogoutButton from "./LogoutButton";
import { Link } from "react-router-dom";

const NavBar = () => {
  const { user } = useAuth();

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            {/* Show different links based on user access level */}
            {user?.accessLevel === "full" && (
              <>
                <Link
                  to="/new-reservations"
                  className="px-3 py-2 text-gray-700 hover:text-gray-900"
                >
                  New Reservations
                </Link>
                <Link
                  to="/manageReservation"
                  className="px-3 py-2 text-gray-700 hover:text-gray-900"
                >
                  Manage Reservations
                </Link>
              </>
            )}
            {/* Show for both full admin and edit access */}
            {(user?.accessLevel === "full" || user?.accessLevel === "edit") && (
              <Link
                to="/confirmed-reservations"
                className="px-3 py-2 text-gray-700 hover:text-gray-900"
              >
                Confirmed Reservations
              </Link>
            )}
          </div>

          {/* Logout button */}
          <div className="flex items-center">{user && <LogoutButton />}</div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
